import { createSlice } from "@reduxjs/toolkit";
import { IEstablishmentState } from "src/types/establishments";

const initialState: IEstablishmentState = {
  isLoading: false,
  error: null,
  establishments: [],
};

const slice = createSlice({
  name: "establishments",
  initialState,
  reducers: {
    // Get establishments list
    getEstablishmentsList(state) {
      state.isLoading = true;
    },
    getEstablishmentsListSuccess(state, action) {
      state.isLoading = false;
      state.establishments = action.payload;
    },

    getEstablishmentsListFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getEstablishmentsList,
  getEstablishmentsListFailure,
  getEstablishmentsListSuccess,
} = slice.actions;

export default slice.reducer;
