const ROOTS = {
  AUTH: "/auth",
  DASHBOARD: "/dashboard",
};

export const paths = {
  redirection: "/redirection",
  page403: "/403",
  page404: "/404",
  page500: "/500",

  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
    // newPassword: `${ROOTS.AUTH}/new-password`,
    newPassword: (email: string, token: string) =>
      `${ROOTS.DASHBOARD}/new-password/${email}/${token}`,
    forgotIdentifier: `${ROOTS.AUTH}/forgot-identifier`,
  },
  dashboard: {
    root: `${ROOTS.DASHBOARD}`,
    account: `${ROOTS.DASHBOARD}/account`,
    journal: {
      root: `${ROOTS.DASHBOARD}/journal`,
      list: `${ROOTS.DASHBOARD}/journal/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/journal/${id}/details`,
    },
    services: {
      root: `${ROOTS.DASHBOARD}/services`,
      list: `${ROOTS.DASHBOARD}/services/list`,
      details: (id: string) => `${ROOTS.DASHBOARD}/services/${id}/details`,
    },
  },
};
