import { takeEvery, takeLeading } from "redux-saga/effects";
import {
  authorisation,
  editNotificationsPreferences,
  login,
  passwordLost,
  resetPassword,
  updateProfile,
} from "../slices/auth";
import { getDriversAround } from "../slices/drivers";
import { getEstablishmentsList } from "../slices/establishment";
import {
  getNotifications,
  markNotificationRead,
} from "../slices/notifications";
import { getListPatients } from "../slices/patients";
import {
  addReservation,
  addReservationMultiplePatients,
  addReview,
  cancelReservation,
  getListReservation,
} from "../slices/reservation";
import { addService, getListServices, updateService } from "../slices/services";
import {
  handleLogin,
  handleAuthorisation,
  handleUpdateProfile,
  handlePasswordLost,
  handleEditNotificationsPreferences,
  handleResetPassword,
} from "./handlers/auth";
import { handleGetListDrivers } from "./handlers/drivers";
import { handleGetListEstablishment } from "./handlers/establishment";
import {
  handleGetListNotifications,
  handleMarkNotificationRead,
} from "./handlers/notifications";
import { handleGetListPatients } from "./handlers/patient";
import {
  handleAddReservation,
  handleAddReservationMultiplePatient,
  handleAddReview,
  handleCancelReservation,
  handleGetListReservation,
} from "./handlers/reservation";
import {
  handleAddService,
  handleGetListService,
  handleUpdateService,
} from "./handlers/services";

// redux

export function* watcherSaga() {
  // Auth
  yield takeLeading(authorisation.type, handleAuthorisation);
  yield takeLeading(login.type, handleLogin);
  yield takeLeading(updateProfile.type, handleUpdateProfile);
  yield takeLeading(passwordLost.type, handlePasswordLost);
  yield takeLeading(
    editNotificationsPreferences.type,
    handleEditNotificationsPreferences
  );
  yield takeLeading(resetPassword.type, handleResetPassword);

  // Patients
  yield takeLeading(getListPatients.type, handleGetListPatients);
  // yield takeLeading(getDriversAround.type, handle);

  // reservation
  yield takeLeading(addReservation.type, handleAddReservation);
  yield takeEvery(
    addReservationMultiplePatients.type,
    handleAddReservationMultiplePatient
  );
  yield takeEvery(getListReservation.type, handleGetListReservation);
  yield takeLeading(cancelReservation.type, handleCancelReservation);
  // review
  yield takeLeading(addReview.type, handleAddReview);
  // services
  yield takeEvery(addService.type, handleAddService);
  yield takeEvery(getListServices.type, handleGetListService);
  yield takeLeading(updateService.type, handleUpdateService);

  // notifications
  yield takeLeading(getNotifications.type, handleGetListNotifications);
  yield takeLeading(markNotificationRead.type, handleMarkNotificationRead);

  // establishment
  yield takeLeading(getEstablishmentsList.type, handleGetListEstablishment);

  // drivers
  yield takeLeading(getDriversAround.type, handleGetListDrivers);
}
