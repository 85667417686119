// i18n.js

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Importez les fichiers de traduction
import translationEN from "./langs/en.json";
import translationFR from "./langs/fr.json";

// Les langues et leurs traductions
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

const lng = localStorage.getItem("i18nextLng");

i18n
  .use(initReactI18next) // initialise react-i18next
  .init({
    resources,
    lng,
    fallbackLng: "fr", // langue de secours si la langue spécifiée n'est pas trouvée
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
