import { useEffect, useCallback, useState } from "react";
// routes
import { paths } from "src/routes/paths";
import { Navigate, useLocation, useNavigate } from "react-router";
// redux
import { useSelector } from "src/redux/store";

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { authenticated } = useSelector((state) => state.auth);

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!authenticated) {
      navigate(paths.auth.login, { replace: true });
    } else {
      setChecked(true);
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      check();
    }, 100); // Attendre 1 seconde avant de vérifier l'authentification

    return () => clearTimeout(timer); // Nettoyer le timer si le composant est démonté avant la fin du délai
  }, [check]);

  if (!checked) {
    return null;
  }

  return <>{children}</>;
}
/*export default function AuthGuard({ children }: any) {
  const { authenticated } = useSelector((state) => state.auth);

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  console.log(authenticated);
  if (!authenticated) {
    console.log("Notttttttttttttttttttttttt");
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={paths.auth.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}*/
