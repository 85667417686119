import { apiConfig } from "src/config";
import axios from "src/utils/axios";
import { ApiPaths } from "../../../utils/api-paths";
//
export function requestNotificationsList() {
  return axios.get(ApiPaths.getNotifications, apiConfig());
}

export function requestMarkNotificationAsRead(id: any) {
  return axios.post(
    `${ApiPaths.markNotificationAsRead}/${id}`,
    {},
    apiConfig()
  );
}
