import { useTranslation } from "react-i18next";
import { useCallback } from "react";
// components
import { allLangs, defaultLang } from "./config-lang";

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t } = useTranslation();

  const langStorage = localStorage.getItem("i18nextLng") || "fr";

  const currentLang = allLangs.find((lang) => lang.value === langStorage);

  const onChangeLang = useCallback(
    (newlang: string) => {
      localStorage.setItem("i18nextLng", newlang);
      i18n.changeLanguage(newlang);
    },
    [i18n]
  );

  return {
    allLangs,
    t,
    currentLang,
    onChangeLang,
  };
}
