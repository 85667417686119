import { apiConfig } from "src/config";
import axios from "src/utils/axios";
import { ApiPaths } from "../../../utils/api-paths";
//
export function requestGetServiceList() {
  return axios.get(ApiPaths.getServices, apiConfig());
}

export function requestAddService(dataToSend: any) {
  return axios.post(ApiPaths.addService, dataToSend, apiConfig());
}

export function requestUpdateService(id: any, data: any) {
  return axios.put(`${ApiPaths.updateService}/${id}`, data, apiConfig());
}
