import { createSlice } from "@reduxjs/toolkit";
import { IDriversState } from "src/types/drivers";

const initialState: IDriversState = {
  isLoading: false,
  error: null,
  drivers: [
    /* {
      name: "Ambulance 2145",
      company: "ChronoDigo",
      type: "ambulance",
      address: { lat: 33.892166, lng: 9.561555 },
    },
    {
      name: "Ambulance 2145",
      company: "ChronoDigo",
      type: "ambulance",
      address: { lat: 0, lng: 0 },
    },
    {
      name: "Ambulance 2145",
      company: "ChronoDigo",
      type: "ambulance",
      address: { lat: 0, lng: 0 },
    },
    {
      name: "Ambulance 2145",
      company: "ChronoDigo",
      type: "ambulance",
      address: { lat: 0, lng: 0 },
    },
    {
      name: "TAXI 2145",
      company: "ChronoDigo",
      type: "TAXI",
      address: { lat: 0, lng: 0 },
    }, */
  ],
};

const slice = createSlice({
  name: "drivers",
  initialState,
  reducers: {
    // Get Drivers Around
    getDriversAround(state, action) {
      state.isLoading = true;
    },
    getDriversAroundSuccess(state, action) {
      state.isLoading = false;
      state.drivers = action.payload;
    },

    getDriversAroundFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getDriversAround,
  getDriversAroundFailure,
  getDriversAroundSuccess,
} = slice.actions;

export default slice.reducer;
