import { apiConfig } from "src/config";
import axios from "src/utils/axios";
import { ApiPaths } from "../../../utils/api-paths";
//
export function requestAddReservation(dataToSend: any) {
  return axios.post(ApiPaths.addReservation, dataToSend, apiConfig());
}

export function requestAddReservationMultiplePatients(dataToSend: any) {
  return axios.post(
    ApiPaths.addReservationMultiplePatients,
    dataToSend,
    apiConfig()
  );
}

export function requestGetReservationList() {
  return axios.get(ApiPaths.getListReservations, apiConfig());
}

export function requestCancelReservation(id: any) {
  return axios.post(`${ApiPaths.cancelReservations}/${id}`, {}, apiConfig());
}

export function requestResquestCarrier(id: any, data: any) {
  console.log("requestResquestCarrier", data);
  return axios.post(`${ApiPaths.RequestCarrier}/${id}`, data, apiConfig());
}

export function requestAddReview(id: any, dataToSend: any) {
  return axios.post(`${ApiPaths.addReview}/${id}`, dataToSend, apiConfig());
}
