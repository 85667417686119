import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone";
import { styled } from "@mui/material/styles";

import { formatDistance, subDays } from "date-fns";
import { fr, enUS } from "date-fns/locale";

import {
  getNotifications,
  markNotificationRead,
} from "src/redux/slices/notifications";
import { useDispatch, useSelector } from "src/redux/store";
import useLocales from "src/locales/use-locales";
import { NOTIFICATIONS_TYPES, VEHICULES_TYPES } from "src/utils/const";
import { TTNotification } from "src/types/notifications";

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

function HeaderNotifications() {
  const dispatch = useDispatch();
  const { t } = useLocales();
  const { refresh, notifications } = useSelector((state) => state.notification);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const lng = localStorage.getItem("i18nextLng") || "fr";

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const CardLogo = styled("img")(
    ({ theme }) => `
     // border: 1px solid ${theme.colors.alpha.black[30]};
      border-radius: ${theme.general.borderRadius};
      padding: ${theme.spacing(1)};
      margin-right: ${theme.spacing(2)};
      background: #F2F7FB;
      width: 40px;
      height: 40px;
`
  );

  const handleMarkAsRead = (notif: TTNotification) => {
    if (!notif.recipient_has_read) {
      dispatch(markNotificationRead({ id: notif.id }));
    }
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [refresh]);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={
              notifications.filter((item) => !item.recipient_has_read).length
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box
          sx={{ p: 2, width: 450 }}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />

        {notifications.length === 0 ? (
          <Stack alignItems="center" sx={{ p: 5 }}>
            <Typography sx={{ textTransform: "none" }} variant="caption">
              {t("notifications.noNotifications")}
            </Typography>
          </Stack>
        ) : (
          <List sx={{ p: 0 }}>
            {notifications.map((notif) => (
              <>
                <ListItem
                  onClick={() => handleMarkAsRead(notif)}
                  sx={{
                    p: 2,
                    minWidth: 350,
                    justifyContent: "space-between",
                    cursor: !notif.recipient_has_read ? "pointer" : "default",
                    //   display: { xs: "block", sm: "flex" },
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <>
                      <CardLogo
                        src={
                          notif.vehicle_type === VEHICULES_TYPES.taxi
                            ? "/static/images/dashboard/journal/taxi-simple.png"
                            : "/static/images/dashboard/journal/ambulance-simple.png"
                        }
                      />

                      <Box sx={{ flexGrow: 1, marginLeft: 1 }}>
                        {notif.notif_type ===
                        NOTIFICATIONS_TYPES.NEW_MISSION ? (
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="text.secondary"
                          >
                            {`${t(`typesNotifications.${notif.notif_type}`)}`}

                            {notif?.driver_full_name !== "" &&
                            notif?.driver_full_name !== undefined
                              ? `${t("notifications.with")} ${
                                  notif?.driver_full_name
                                }`
                              : notif?.carrier_full_name !== "" &&
                                notif?.carrier_full_name !== undefined
                              ? ` ${t("notifications.with")} ${
                                  notif?.carrier_full_name
                                }`
                              : ""}
                          </Typography>
                        ) : (
                          <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="text.secondary"
                          >
                            {`${
                              notif?.driver_full_name !== "" &&
                              notif?.driver_full_name !== undefined
                                ? notif?.driver_full_name
                                : notif?.carrier_full_name !== "" &&
                                  notif?.carrier_full_name !== undefined
                                ? notif?.carrier_full_name
                                : ""
                            } ${t(`typesNotifications.${notif.notif_type}`)}`}
                          </Typography>
                        )}

                        {notif.comment !== "" && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            {notif.comment}
                          </Typography>
                        )}

                        {/*   {notif.mission_id !== "" && (
                          <Typography
                            component="span"
                            variant="body2"
                            color="text.secondary"
                          >
                            Mission N° {notif.mission_id}
                          </Typography> 
                        )} */}
                      </Box>
                    </>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="caption"
                      sx={{
                        textAlign: "center",
                        textTransform: "none",
                        marginRight: 2,
                        marginLeft: 3,
                        width: "100px",
                      }}
                    >
                      {formatDistance(new Date(notif.created_at), new Date(), {
                        // addSuffix: true,
                        locale: lng === "fr" ? fr : enUS,
                      })}
                    </Typography>
                    {!notif.recipient_has_read && (
                      <Box
                        sx={{
                          // top: 26,
                          width: 8,
                          height: 8,
                          right: 20,
                          borderRadius: "50%",
                          bgcolor: "secondary.main",
                          position: "absolute",
                        }}
                      />
                    )}
                  </Stack>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        )}
      </Popover>
    </>
  );
}

export default HeaderNotifications;
