import { call, put } from "redux-saga/effects";
import {
  addReservationFailure,
  addReservationSuccess,
  cancelReservationFailure,
  cancelReservationSuccess,
  getListReservationFailure,
  getListReservationSuccess,
} from "src/redux/slices/reservation";
import {
  addServiceFailure,
  addServiceSuccess,
  getListServicesFailure,
  getListServicesSuccess,
  updateServiceFailure,
  updateServiceSuccess,
} from "src/redux/slices/services";
import {
  requestAddReservation,
  requestCancelReservation,
  requestGetReservationList,
} from "../requests/reservation";
import {
  requestAddService,
  requestGetServiceList,
  requestUpdateService,
} from "../requests/services";

// Get list des services
export function* handleGetListService(action: any): Generator<any, any, any> {
  try {
    const response = yield call(requestGetServiceList);

    if (response.status === 200) {
      console.log(response.data);
      yield put(getListServicesSuccess(response.data.services));
    }
  } catch (error) {
    yield put(getListServicesFailure(error.message));
  }
}

// Add Service
export function* handleAddService(action: any): Generator<any, any, any> {
  const { dataToSend, translate, toast, onClose } = action.payload;

  try {
    const response = yield call(requestAddService, dataToSend);
    const { data, message } = response;

    console.log(response);
    if (response.status === 200) {
      yield put(addServiceSuccess(data));
      toast(translate(`backendMsg.service_created_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  } catch (error) {
    console.log(error);
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(addServiceFailure(error.error_code));
  }
}

// Update Service
export function* handleUpdateService(action: any): Generator<any, any, any> {
  const { id, translate, toast, onClose, dataToSend } = action.payload;

  try {
    const response = yield call(requestUpdateService, id, dataToSend);
    const { data } = response;

    if (response.status === 200) {
      console.log(response);
      yield put(updateServiceSuccess(data));
      toast(translate(`backendMsg.service_updated_success`), {
        variant: "success",
        autoHideDuration: 3000,
      });
      onClose();
    }
  } catch (error) {
    console.log(error);
    toast(translate(`backendMsg.${error.error_code}`), {
      variant: "error",
      autoHideDuration: 3000,
    });

    yield put(updateServiceFailure(error.error_code));
  }
}
