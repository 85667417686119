import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import authReducer from "./slices/auth";
import driversReducer from "./slices/drivers";
import patientsReducer from "./slices/patients";
import reservationReducer from "./slices/reservation";
import servicesReducer from "./slices/services";
import notificationsReducer from "./slices/notifications";
import establishmentsReducer from "./slices/establishment";

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const authPersistConfig = {
  key: "auth",
  storage,
  keyPrefix: "redux-",
  whitelist: ["authenticated", "user"],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  drivers: driversReducer,
  patients: patientsReducer,
  reservation: reservationReducer,
  services: servicesReducer,
  notification: notificationsReducer,
  establishments: establishmentsReducer,
});

export default rootReducer;
