import axios from "./axios";

export const tokenExpired = (accessToken: string) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Retrieve token expiration time from localStorage
  const expiration = localStorage.getItem("token_expiration");

  if (!expiration) {
    // Token expiration time not found in localStorage
    console.error("Token expiration time not found in localStorage");
    return;
  }

  const timeLeft = parseInt(expiration) - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Token expired");

    // Optionally, you can also remove the expired token from localStorage
    // localStorage.removeItem("access_token");
    localStorage.removeItem("token");
    localStorage.removeItem("token_expiration");
  }, timeLeft);
};

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);

    const expirationTime = Date.now() + 1 * 60 * 60 * 1000;
    localStorage.setItem("token_expiration", expirationTime.toString());

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    console.log("axios", axios.defaults.headers.common.Authorization);

    tokenExpired(accessToken);
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};
