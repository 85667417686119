import axios from "axios";
import { HOST_API, PORT } from "../config";

// const axiosInstance = axios.create({ baseURL: HOST_API });
const axiosInstance = axios.create({
  baseURL: HOST_API,
});
axiosInstance.interceptors.response.use(
  (res) => res,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;

