import { useEffect } from "react";
import NProgress from "nprogress";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

function SuspenseLoader() {
  /* useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []); */

  const StyledRoot = styled("div")(({ theme }) => ({
    right: 0,
    bottom: 0,
    zIndex: 9998,
    width: "100%",
    height: "100%",
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.default,
  }));

  return (
    <StyledRoot>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress size={64} disableShrink thickness={3} />
      </Box>
    </StyledRoot>
  );
}

export default SuspenseLoader;
