export enum ApiPaths {
  // Auth
  authorization = "/authorization",
  login = "/v1/login",
  getProfile = "/restricted/v1/my/user",
  updateProfile = "/restricted/v1/my/account",
  lostPassword = "/v1/user/lost",
  resetPassword = "/v1/user/password",

  editNotificationsPreferences = "/restricted/v1/my/notification",

  // Drivers Around
  getDriversAround = "/restricted/v1/drivers-around",

  // Patients
  getPatient = "/restricted/v1/user/patients",

  // reservation
  addReservation = "/restricted/v1/my/mission",
  addReservationMultiplePatients = "/restricted/v1/health/mission",
  getListReservations = "/restricted/v1/my/missions/ALL",
  cancelReservations = "/restricted/v1/my/patient/mission/cancel",
  RequestCarrier = "/restricted/v1/request-carrier",

  // review
  addReview = "/restricted/v1/my/comment",

  // services
  addService = "/restricted/v1/my/service",
  updateService = "/restricted/v1/my/service",
  getServices = "/restricted/v1/my/services",

  // notifications
  getNotifications = "/restricted/v1/my/notifications",
  markNotificationAsRead = "/restricted/v1/my/notification",

  // establishments
  getEstablishments = "/v1/etablissments",
}
