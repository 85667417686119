import { createSlice } from "@reduxjs/toolkit";
import { IPatientState } from "src/types/patients";

const initialState: IPatientState = {
  isLoading: false,
  error: null,
  patients: [],
};

const slice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    // Get Patients
    getListPatients(state) {
      state.isLoading = true;
    },
    getListPatientsSuccess(state, action) {
      state.isLoading = false;
      state.patients = action.payload;
    },

    getListPatientsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getListPatients,
  getListPatientsFailure,
  getListPatientsSuccess,
} = slice.actions;

export default slice.reducer;
