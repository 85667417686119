import { createSlice } from "@reduxjs/toolkit";
import { INotificationsState } from "src/types/notifications";

const initialState: INotificationsState = {
  isLoading: false,
  error: null,
  notifications: [
    /*  {
      id: "123",
      created_at: "555",
      mission_id: "454",
      notif_type: "MISSION_ACCEPTED",
      comment: "",
      recipient: "",
      recipient_as_read: true,
      vehicle_type: "VSL",
      driver_full_name: "Rania",
      driver_id: "10",
      carrier_id: "00",
      carrier_full_name: "",
      recipient_has_read: true,
    },
    {
      id: "223",
      created_at: "555",
      mission_id: "454",
      notif_type: "MISSION_BEGIN",
      comment: "",
      recipient: "",
      recipient_as_read: true,
      vehicle_type: "AMBULANCE",
      driver_full_name: "Ahmed",
      driver_id: "10",
      carrier_id: "00",
      carrier_full_name: "",
      recipient_has_read: false,
    }, */
  ],
  refresh: 0,
};

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    // Get notifications
    getNotifications(state) {
      state.isLoading = true;
    },
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    getNotificationsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    markNotificationRead(state, action) {
      state.isLoading = true;
    },
    markNotificationReadSuccess(state, action) {
      state.isLoading = false;
      state.refresh += 1;
      state.error = null;
    },
    markNotificationReadFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const {
  getNotifications,
  getNotificationsFailure,
  getNotificationsSuccess,
  markNotificationRead,
  markNotificationReadFailure,
  markNotificationReadSuccess,
} = slice.actions;

export default slice.reducer;
