import { createSlice } from "@reduxjs/toolkit";
import { IServiceState } from "src/types/services";

const initialState: IServiceState = {
  isLoading: false,
  loadingOperation: false,
  error: null,
  services: [],
  refresh: 0,
};

const slice = createSlice({
  name: "services",
  initialState,
  reducers: {
    // get services
    getListServices(state, action) {
      state.isLoading = true;
    },
    getListServicesSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.services = action.payload;
    },
    getListServicesFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // add service
    addService(state, action) {
      state.isLoading = true;
    },
    addServiceSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.refresh += 1;
    },
    addServiceFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // update reservation
    updateService(state, action) {
      state.loadingOperation = true;
    },
    updateServiceSuccess(state, action) {
      state.loadingOperation = false;
      state.error = null;
      state.refresh += 1;
    },
    updateServiceFailure(state, action) {
      state.loadingOperation = false;
      state.error = action.payload;
    },
  },
});

export const {
  getListServices,
  getListServicesFailure,
  getListServicesSuccess,
  addService,
  addServiceFailure,
  addServiceSuccess,
  updateService,
  updateServiceFailure,
  updateServiceSuccess,
} = slice.actions;

export default slice.reducer;
