import { useRoutes } from "react-router-dom";
import router from "src/routes/index";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import { CssBaseline } from "@mui/material";
import ThemeProvider from "./theme/ThemeProvider";
import { Provider } from "react-redux";
import { createStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/rootReducer";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";

function App() {
  const content = useRoutes(router);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider>
          <CssBaseline />
          <Provider store={store}>{content}</Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
