import { useCallback, useEffect, useState } from "react";
// routes
import { paths } from "src/routes/paths";
import { useLocation, useNavigate, Navigate } from "react-router";
// redux
import { useSelector } from "src/redux/store";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

/*export default function MamberGuard({ children }: any) {
  const { authenticated } = useSelector((state: any) => state.auth);
  const { pathname } = useLocation();

  if (authenticated) {
    return <Navigate to={paths.dashboard.root} />;
  }

  return <>{children}</>;
}*/

export default function MamberGuard({ children }: Props) {
  const navigate = useNavigate();

  const { authenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  const check = useCallback(() => {
    if (authenticated) {
      navigate(paths.dashboard.root, { replace: true });

      if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
      }
      // navigate(location.pathname, { replace: true });
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}
/*export default function MamberGuard({ children }: Props) {
  const navigate = useNavigate();

  const { authenticated } = useSelector((state) => state.auth);

  const check = useCallback(() => {
    const { pathname } = useLocation();

    console.log(authenticated);
    if (authenticated) {
      navigate(paths.dashboard.root, { replace: true });
    }
  }, [authenticated, navigate]);

  useEffect(() => {
    check();
  }, [check]);

  return <>{children}</>;
}*/
