import { call, put } from "redux-saga/effects";
import {
  getEstablishmentsListFailure,
  getEstablishmentsListSuccess,
} from "src/redux/slices/establishment";
import { requestEstablishmentsList } from "../requests/estabishment";

export function* handleGetListEstablishment(
  action: any
): Generator<any, any, any> {
  try {
    const response = yield call(requestEstablishmentsList);
    if (response.status === 200) {
      yield put(getEstablishmentsListSuccess(response.data.etablissments));
    }
  } catch (error) {
    yield put(getEstablishmentsListFailure(error.message));
  }
}
