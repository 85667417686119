import { call, put } from "redux-saga/effects";
import {
  getNotificationsFailure,
  getNotificationsSuccess,
  markNotificationReadFailure,
  markNotificationReadSuccess,
} from "src/redux/slices/notifications";

import {
  requestMarkNotificationAsRead,
  requestNotificationsList,
} from "../requests/notifications";

export function* handleGetListNotifications(
  action: any
): Generator<any, any, any> {
  try {
    const response = yield call(requestNotificationsList);

    if (response.status === 200) {
      console.log(response.data);
      yield put(getNotificationsSuccess(response.data.notifications));
    }
  } catch (error) {
    yield put(getNotificationsFailure(error.message));
  }
}

export function* handleMarkNotificationRead(
  action: any
): Generator<any, any, any> {
  try {
    const { id } = action.payload;

    const response = yield call(requestMarkNotificationAsRead, id);
    const { data } = response.data;
    if (response.status === 200) {
      yield put(markNotificationReadSuccess(data));
    }
  } catch (error) {
    yield put(markNotificationReadFailure(error.message));
  }
}
