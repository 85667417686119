import { call, put } from "redux-saga/effects";
import {
  getDriversAroundFailure,
  getDriversAroundSuccess,
} from "src/redux/slices/drivers";
import { requestDriversAround } from "../requests/drivers";

export function* handleGetListDrivers(action: any): Generator<any, any, any> {
  const { dataToSend } = action.payload;
  console.log("dataToSend", dataToSend);
  try {
    const response = yield call(requestDriversAround, dataToSend);
    if (response.status === 200) {
      yield put(getDriversAroundSuccess(response.data.drivers));
    }
  } catch (error) {
    yield put(getDriversAroundFailure(error.message));
  }
}
