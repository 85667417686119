import { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";

import SidebarLayout from "src/layouts/SidebarLayout";
import BaseLayout from "src/layouts/BaseLayout";

import SuspenseLoader from "src/components/SuspenseLoader";
import AuthLayout from "src/layouts/auth";
import AuthGuard from "src/auth/guard/auth-guard";
import { paths } from "./paths";
import MamberGuard from "src/auth/guard/member-guard";

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status

const Status404 = Loader(
  lazy(() => import("src/content/pages/Status/Status404"))
);
const Status500 = Loader(
  lazy(() => import("src/content/pages/Status/Status500"))
);
const StatusComingSoon = Loader(
  lazy(() => import("src/content/pages/Status/ComingSoon"))
);

const StatusMaintenance = Loader(
  lazy(() => import("src/content/pages/Status/Maintenance"))
);

// Auth
const LoginPage = Loader(lazy(() => import("src/pages/auth/login")));
const ForgotPassowrdPage = Loader(
  lazy(() => import("src/pages/auth/forgot-password"))
);

// Account
const ProfilePage = Loader(
  lazy(() => import("src/pages/dashboard/account/profile"))
);

const ResetPage = Loader(lazy(() => import("src/pages/auth/reset-password")));
const ForgotIdentifierPage = Loader(
  lazy(() => import("src/pages/auth/forgot-identifier"))
);

// Dashboard
const HomePage = Loader(lazy(() => import("src/pages/dashboard/home/index")));

// Journal
const JournalListPage = Loader(
  lazy(() => import("src/pages/dashboard/journal/list"))
);

const ReservationDetailsPage = Loader(
  lazy(() => import("src/pages/dashboard/journal/details"))
);

// services
const ServicesListPage = Loader(
  lazy(() => import("src/pages/dashboard/services/list"))
);
const routes: RouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Navigate to={paths.dashboard.root} replace />{" "}
      </AuthGuard>
    ),
  },

  {
    path: "/auth",
    // element: <BaseLayout />,
    children: [
      {
        path: "login",
        element: (
          <MamberGuard>
            <AuthLayout>
              <LoginPage />
            </AuthLayout>
          </MamberGuard>
        ),
      },
      {
        path: "forgot-password",
        element: (
          <AuthLayout>
            <ForgotPassowrdPage />
          </AuthLayout>
        ),
      },
      {
        path: "reset-password/:token/:email",
        element: (
          <AuthLayout>
            <ResetPage />
          </AuthLayout>
        ),
      },
      {
        path: "forgot-identifier",
        element: (
          <AuthLayout>
            <ForgotIdentifierPage />
          </AuthLayout>
        ),
      },
    ],
  },
  {
    path: "",
    element: <BaseLayout />,
    children: [
      /*{
        path: "/",
        element: <Overview />,
      }, */
      {
        path: "overview",
        element: <Navigate to="/" replace />,
      },
      {
        path: "status",
        children: [
          {
            path: "",
            element: <Navigate to="404" replace />,
          },
          {
            path: "404",
            element: <Status404 />,
          },
          {
            path: "500",
            element: <Status500 />,
          },
          {
            path: "maintenance",
            element: <StatusMaintenance />,
          },
          {
            path: "coming-soon",
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: "*",
        element: <Status404 />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <SidebarLayout />
      </AuthGuard>
    ),
    children: [
      {
        // path: "",
        //  element: <Navigate to="home" replace />,
        element: <Navigate to={"/dashboard/home"} replace />,
        index: true,
      },
      {
        path: "home",
        element: <HomePage />,
      },

      {
        path: "account",
        element: <ProfilePage />,
      },

      {
        path: "journal",
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },
          {
            path: "list",
            element: <JournalListPage />,
          },
          {
            path: ":id/details",
            element: <ReservationDetailsPage />,
          },
        ],
      },
      {
        path: "services",
        children: [
          {
            path: "",
            element: <Navigate to="list" replace />,
          },
          {
            path: "list",
            element: <ServicesListPage />,
          },
          {
            path: ":id/details",
            element: <ReservationDetailsPage />,
          },
        ],
      },
    ],
  },
];

export default routes;
